<template>
  <div class="p-grid">
    <div class="p-col-12 p-md-8">
      <Panel header="Gerenciamento de Grupos" style="height: 100%">
        <Toolbar class="p-mb-4">
          <template v-slot:left>
            <Button
              label="Novo"
              icon="pi pi-plus"
              class="p-button-success p-mr-2 p-mb-2"
              @click="showCreateGroup()"
            />
          </template>
          <template v-slot:right> </template>
        </Toolbar>
        <DataTable
          ref="dt"
          dataKey="id"
          class="p-datatable-sm"
          :value="groups"
          :paginator="true"
          :rows="10"
          :rowsPerPageOptions="[10, 20, 50]"
          :filters="filters"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Mostrando de {first} a {last} no total de {totalRecords} grupos."
        >
          <template #header>
            <div
              class="
                table-header
                p-d-flex p-flex-column p-flex-md-row p-jc-md-between
              "
            >
              <h5 class="p-m-0">Grupos</h5>
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                  v-model="filters['global'].value"
                  placeholder="Buscar..."
                />
              </span>
            </div>
          </template>
          <template #empty>
            <div class="p-text-center">Nenhum resultado encontrado...</div>
          </template>
          <Column header="Nome do Grupo" field="descricao">
            <template #body="slotProps">
              <span class="p-column-title">Nome do Grupo:</span>
              {{ slotProps.data.descricao }}
            </template>
          </Column>
          <Column header="Permissões" field="permissoes">
            <template #body="slotProps">
              <span class="p-column-title">Permissões:</span>
              <Button
                @click="showDataTablePermissions(slotProps.data.permissoes)"
                label="Visualizar"
                class="p-button-link"
              ></Button>
              <OverlayPanel
                ref="op"
                appendTo="body"
                id="overlay_panel"
                style="width: 500px"
                :showCloseIcon="true"
                :breakpoints="{ '960px': '75vw' }"
              >
                <DataTable
                  :value="permissao"
                  :paginator="true"
                  :rows="5"
                  :filters="filtersPermissions"
                  class="p-datatable-sm"
                >
                  <template #header>
                    <div
                      class="
                        table-header
                        p-d-flex p-flex-column p-flex-md-row p-jc-md-between
                      "
                    >
                      <h5 class="p-m-0">Permissões</h5>
                      <span class="p-input-icon-left">
                        <i class="pi pi-search" />
                        <InputText
                          v-model="filtersPermissions['global'].value"
                          placeholder="Buscar..."
                        />
                      </span>
                    </div>
                  </template>

                  <Column>
                    <template #body="slotProps">
                      {{ slotProps.data.descricao.replace("ROLE_", "") }}
                    </template>
                  </Column>
                </DataTable>
              </OverlayPanel>
            </template>
          </Column>
          <Column>
            <template #body="slotProps">
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-success p-mr-2"
                @click="showUpdateGroup(slotProps.data)"
                v-tooltip.top="'CLIQUE PARA EDITAR'"
              />
              <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-warning"
                @click="showRemoveGroup(slotProps.data)"
                v-tooltip.top="'CLIQUE PARA EXCLUIR'"
              />
            </template>
          </Column>
        </DataTable>
      </Panel>
    </div>
  </div>

  <!--MODAL CADASTRAR-->
  <Dialog
    v-model:visible="groupDialog"
    header="Cadastro de Grupos"
    :style="{ width: '800px' }"
    :modal="true"
    @hide="hideDialogs()"
  >
    <div class="p-fluid" style="padding: 30px">
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12">
          <label for="nome">Nome do Grupo</label>
          <InputText
            id="nome"
            v-model="group.descricao"
            placeholder="Digite o nome do grupo."
            autofocus
            :class="{ 'p-invalid': submitted && !group.descricao }"
          />
          <small class="p-invalid" v-if="submitted && !group.descricao"
            >Nome do grupo e obrigatório. Min.3 e Max.50.</small
          >
        </div>
      </div>
      <label for="nome">Permissões do Grupo</label>
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-col-12" style="padding: 5px">
          <Listbox
            v-model="group.permissoes"
            :options="permissoes"
            :multiple="true"
            :filter="true"
            optionLabel="descricao"
            listStyle="max-height:350px"
            filterPlaceholder="DIGITE O NOME DA PERMISSÃO."
            emptyFilterMessage="Nenhum Resultado..."
            emptyMessage="Nenhum Resultado..."
          >
            <template #header>
              <div style="padding: 15px">
                <Checkbox
                  id="binary"
                  v-model="selectedAllPermissoes"
                  :binary="true"
                  @click="allPermissoes()"
                  class="p-mr-1"
                />
                <small>Todas as Permissões</small>
              </div>
            </template>
            <template #option="slotProps">
              <div class="product-list-detail">
                <i class="pi pi-unlock product-category-icon p-mr-3"> </i>
                <span class="product-category">
                  {{ slotProps.option.descricao.replace("ROLE_", "") }}.</span
                >
              </div>
            </template>
          </Listbox>
        </div>
      </div>
    </div>
    <template #footer>
      <Button
        label="Salvar"
        class="p-button-raised"
        icon="pi pi-check"
        @click="createOrUpdateGroup()"
      />
      <Button
        label="Cancelar"
        icon="pi pi-times"
        class="p-button-raised p-button-text"
        @click="hideDialogs()"
      />
    </template>
  </Dialog>
  <!--FIM MODAL CADASTRAR-->

  <!--MODAL DELETAR-->
  <Dialog
    v-model:visible="groupRemoveDialog"
    header="Deseja excluir este usuário?"
    :style="{ width: '450px' }"
    :modal="true"
    @hide="hideDialogs()"
  >
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
      <span v-if="group"
        >Tem certeza de que deseja excluir do sistema o grupo
        <b>{{ group.descricao }} ?</b>
      </span>
    </div>
    <template #footer>
      <Button
        label="Sim"
        icon="pi pi-check"
        class="p-button-raised"
        @click="deleteGroup()"
      />
      <Button
        label="Não"
        icon="pi pi-times"
        class="p-button-raised p-button-text"
        @click="hideDialogs()"
      />
    </template>
  </Dialog>
  <!--FIM MODAL DELETAR-->
</template>

<script>
import { FilterMatchMode } from "primevue/api";

import AuthService from "@/service/Auth/AuthService";
import GroupService from "@/service/Grupos/GroupService";
import PermissionService from "@/service/Permissoes/PermissionService";

export default {
  data() {
    return {
      selectedGroup: null,
      groups: null,
      group: {
        id: null,
        descricao: null,
        permissoes: [],
      },
      permissoes: null,
      selectedAllPermissoes: false,
      permissao: {
        id: null,
        descricao: null,
      },
      groupDialog: false,
      groupRemoveDialog: false,
      filters: {},
      filtersPermissions: {},
    };
  },
  authService: null,
  groupService: null,
  permissionService: null,
  created() {
    this.authService = new AuthService();
    this.groupService = new GroupService();
    this.permissionService = new PermissionService();
    this.initFilters();
  },
  mounted() {
    this.findAllGroups();
    this.findAllPermissions();
  },
  methods: {
    showCreateGroup() {
      this.groupDialog = true;
    },
    showUpdateGroup(group) {
      this.group = group;
      this.groupDialog = true;
    },
    showRemoveGroup(group) {
      this.group = group;
      this.groupRemoveDialog = true;
    },
    showDataTablePermissions(permissoes) {
      this.$refs.op.toggle(event);
      this.permissao = permissoes;
    },
    findAllGroups() {
      this.groupService.findAll().then((data) => {
        this.groups = data;
      });
    },
    findAllPermissions() {
      this.permissionService
        .findAll()
        .then((data) => {
          this.permissoes = data;
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.$msgWarn(null);
          }
        });
    },
    createOrUpdateGroup() {
      if (this.group.id === null) {
        this.groupService
          .create(this.group)
          .then((data) => {
            this.$msgSuccess(data);
            this.findAllGroups();
            this.hideDialogs();
          })
          .catch((error) => {
            this.$msgErro(error);
          });
      } else {
        this.groupService
          .update(this.group.id, this.group)
          .then((data) => {
            this.$msgSuccess(data);
            this.findAllGroups();
            this.hideDialogs();
          })
          .catch((error) => {
            this.$msgErro(error);
          });
      }
    },
    deleteGroup() {
      this.groupService
        .delete(this.group.id)
        .then((data) => {
          this.$msgSuccess(data);
          this.findAllGroups();
          this.hideDialogs();
        })
        .catch((error) => {
          this.$msgErro(error);
          console.error(error);
        });
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
      this.filtersPermissions = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
    hideDialogs() {
      this.group = {
        id: null,
        descricao: null,
        permissoes: [],
      };
      this.permissao = {
        id: null,
        descricao: null,
      };
      this.groupDialog = false;
      this.groupRemoveDialog = false;
    },
    allPermissoes() {
      if (this.selectedAllPermissoes === false) {
        this.group.permissoes = this.permissoes;
      } else {
        this.group.permissoes = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.p-datatable-sm .p-datatable-tbody > tr > td .p-column-title {
  display: none !important;
}
@media screen and (max-width: 40em) {
  ::v-deep(.p-datatable) {
    &.p-datatable-sm {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;
      }

      .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        width: 100%;
        float: left;
        clear: left;
        border: 0 none;

        .p-column-title {
          padding: 0.4rem;
          min-width: 30%;
          display: inline-block;
          margin: -0.4em 1em -0.4em -0.4rem;
          font-weight: bold;
        }
        &:last-child {
          border-bottom: 1px solid var(--surface-d);
          text-align: right;
        }
        .p-rating {
          display: inline-block;
        }
      }
    }
  }
}
</style>
